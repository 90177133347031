import defaultDeLinks from '~/portals/default__de/links.json'
import defaultEnLinks from '~/portals/default__en/links.json'

import portalTravantoDeDeLinks from '~/portals/portal.travanto.de__de/links.json'
// Production "Lizenznehmer" portals:
import harzTravelDeDeLinks from '~/portals/www.harz-travel.de__de/links.json'
import sauerlandTravelDeDeLinks from '~/portals/www.sauerland-travel.de__de/links.json'
// Production "Travanto" portals:
import allgaeuTravelComDeLinks from '~/portals/www.allgaeu-travel.com__de/links.json'
import amrumTravelDeDeLinks from '~/portals/www.amrum-travel.de__de/links.json'
import bauernhofLodgeDeDeLinks from '~/portals/www.bauernhof-lodge.de__de/links.json'
import boltenhagenTravelDeDeLinks from '~/portals/www.boltenhagen-travel.de__de/links.json'
import buesumTravelComDeLinks from '~/portals/www.buesum-travel.com__de/links.json'
import chiemseeTravelDeDeLinks from '~/portals/www.chiemsee-travel.de__de/links.json'
import cuxhavenLodgeDeDeLinks from '~/portals/www.cuxhaven-lodge.de__de/links.json'
import darssTravelDeDeLinks from '~/portals/www.darss-travel.de__de/links.json'
import fehmarnTravelDeDeLinks from '~/portals/www.fehmarn-travel.de__de/links.json'
import foehrTravelDeDeLinks from '~/portals/www.foehr-travel.de__de/links.json'
import hamburgLodgeDeDeLinks from '~/portals/www.hamburg-lodge.de__de/links.json'
import hamburgLodgeDeEnLinks from '~/portals/www.hamburg-lodge.de__en/links.json'
import hundeLodgeDeDeLinks from '~/portals/www.hunde-lodge.de__de/links.json'
import kielTravelDeDeLinks from '~/portals/www.kiel-travel.de__de/links.json'
import luebeckTravelDeDeLinks from '~/portals/www.luebeck-travel.de__de/links.json'
import lueneburgTravelDeDeLinks from '~/portals/www.lueneburg-travel.de__de/links.json'
import masurenTravelComDeLinks from '~/portals/www.masuren-travel.com__de/links.json'
import masurenTravelComEnLinks from '~/portals/www.masuren-travel.com__en/links.json'
import mueritzTravelDeDeLinks from '~/portals/www.mueritz-travel.de__de/links.json'
import polenTravelComDeLinks from '~/portals/www.polen-travel.com__de/links.json'
import prahaLodgeComDeLinks from '~/portals/www.praha-lodge.com__de/links.json'
import ruegenTravelDeDeLinks from '~/portals/www.ruegen-travel.de__de/links.json'
import scharbeutzTravelDeDeLinks from '~/portals/www.scharbeutz-travel.de__de/links.json'
import schwarzwaldTravelDeDeLinks from '~/portals/www.schwarzwald-travel.de__de/links.json'
import seeFerienwohnungDeDeLinks from '~/portals/www.see-ferienwohnung.de__de/links.json'
import sizilienTravelComDeLinks from '~/portals/www.sizilien-travel.com__de/links.json'
import sizilienTravelComEnLinks from '~/portals/www.sizilien-travel.com__en/links.json'
import stpeterordingTravelDeDeLinks from '~/portals/www.stpeterording-travel.de__de/links.json'
import syltTravelDeDeLinks from '~/portals/www.sylt-travel.de__de/links.json'
import syltTravelDeEnLinks from '~/portals/www.sylt-travel.de__en/links.json'
import timmendorferstrandTravelDeDeLinks from '~/portals/www.timmendorferstrand-travel.de__de/links.json'
import travantoDeDeLinks from '~/portals/www.travanto.de__de/links.json'
import travantoDeEnLinks from '~/portals/www.travanto.de__en/links.json'
import usedomTravelNetDeLinks from '~/portals/www.usedom-travel.net__de/links.json'
import wintersportLodgeDeDeLinks from '~/portals/www.wintersport-lodge.de__de/links.json'

import defaultDeTrustpilot from '~/portals/default__de/trustpilot.json'
import defaultEnTrustpilot from '~/portals/default__en/trustpilot.json'
import portalTravantoDeDeTrustpilot from '~/portals/portal.travanto.de__de/trustpilot.json'
import allgaeuTravelComDeTrustpilot from '~/portals/www.allgaeu-travel.com__de/trustpilot.json'
import boltenhagenTravelDeDeTrustpilot from '~/portals/www.boltenhagen-travel.de__de/trustpilot.json'
import buesumTravelComDeTrustpilot from '~/portals/www.buesum-travel.com__de/trustpilot.json'
import fehmarnTravelDeDeTrustpilot from '~/portals/www.fehmarn-travel.de__de/trustpilot.json'
import foehrTravelDeDeTrustpilot from '~/portals/www.foehr-travel.de__de/trustpilot.json'
import hamburgLodgeDeDeTrustpilot from '~/portals/www.hamburg-lodge.de__de/trustpilot.json'
import kielTravelDeDeTrustpilot from '~/portals/www.kiel-travel.de__de/trustpilot.json'
import luebeckTravelDeDeTrustpilot from '~/portals/www.luebeck-travel.de__de/trustpilot.json'
import lueneburgTravelDeDeTrustpilot from '~/portals/www.lueneburg-travel.de__de/trustpilot.json'
import ruegenTravelDeDeTrustpilot from '~/portals/www.ruegen-travel.de__de/trustpilot.json'
import schwarzwaldTravelDeDeTrustpilot from '~/portals/www.schwarzwald-travel.de__de/trustpilot.json'
import stpeterordingTravelDeDeTrustpilot from '~/portals/www.stpeterording-travel.de__de/trustpilot.json'
import syltTravelDeDeTrustpilot from '~/portals/www.sylt-travel.de__de/trustpilot.json'
import timmendorferstrandTravelDeDeTrustpilot from '~/portals/www.timmendorferstrand-travel.de__de/trustpilot.json'
import travantoDeDeTrustpilot from '~/portals/www.travanto.de__de/trustpilot.json'

const usePortalData = () => {
  // const portalData = useState(() => shallowRef(null))
  //
  // if (portalData.value !== null) {
  //   return {
  //     links: portalData.value.links
  //   }
  // }

  const { currentPortal } = usePortals()
  const currentPortalString = currentPortal.value?.portal?.portal ?? ''
  const currentLangString = currentPortal.value?.portal?.lang ?? ''

  const errorData = {
    links: {
      name: '',
      location: '',
      preposition: '',
      preposition2: '',
      logo: '/svg/logo/logo_travanto.svg',
      regiondiscovers: [],
      regionbookings: [],
      vacation_links: [],
      gastgeber_url: '',
      ferienwohnung_vermieten_url: '',
      footer: {
        link_list: [],
        social_networks: [],
        agb: {
          url: ''
        },
        datenschutz: {
          url: ''
        }
      }
    },
    trustpilot: {
      url: '',
      img_small: '',
      img: '',
      widget: '',
      alt: ''
    }
  }

  const defaultData = {
    de: {
      links: defaultDeLinks,
      trustpilot: defaultDeTrustpilot
    },
    en: {
      links: defaultEnLinks,
      trustpilot: defaultEnTrustpilot
    }
  }

  const dataForAllPortals = {
    'http://portal.travanto.de': {
      de: {
        links: portalTravantoDeDeLinks,
        trustpilot: portalTravantoDeDeTrustpilot
      }
    },
    'http://www.harz-travel.de': {
      de: {
        links: harzTravelDeDeLinks,
        trustpilot: null
      }
    },
    'http://www.sauerland-travel.de': {
      de: {
        links: sauerlandTravelDeDeLinks,
        trustpilot: null
      }
    },
    'http://www.allgaeu-travel.com': {
      de: {
        links: allgaeuTravelComDeLinks,
        trustpilot: allgaeuTravelComDeTrustpilot
      }
    },
    'http://www.amrum-travel.de': {
      de: {
        links: amrumTravelDeDeLinks,
        trustpilot: null
      }
    },
    'http://www.bauernhof-lodge.de': {
      de: {
        links: bauernhofLodgeDeDeLinks,
        trustpilot: null
      }
    },
    'http://www.boltenhagen-travel.de': {
      de: {
        links: boltenhagenTravelDeDeLinks,
        trustpilot: boltenhagenTravelDeDeTrustpilot
      }
    },
    'http://www.buesum-travel.com': {
      de: {
        links: buesumTravelComDeLinks,
        trustpilot: buesumTravelComDeTrustpilot
      }
    },
    'http://www.chiemsee-travel.de': {
      de: {
        links: chiemseeTravelDeDeLinks,
        trustpilot: null
      }
    },
    'http://www.cuxhaven-lodge.de': {
      de: {
        links: cuxhavenLodgeDeDeLinks,
        trustpilot: null
      }
    },
    'http://www.darss-travel.de': {
      de: {
        links: darssTravelDeDeLinks,
        trustpilot: null
      }
    },
    'http://www.fehmarn-travel.de': {
      de: {
        links: fehmarnTravelDeDeLinks,
        trustpilot: fehmarnTravelDeDeTrustpilot
      }
    },
    'http://www.foehr-travel.de': {
      de: {
        links: foehrTravelDeDeLinks,
        trustpilot: foehrTravelDeDeTrustpilot
      }
    },
    'http://www.hamburg-lodge.de': {
      de: {
        links: hamburgLodgeDeDeLinks,
        trustpilot: hamburgLodgeDeDeTrustpilot
      },
      en: {
        links: hamburgLodgeDeEnLinks,
        trustpilot: null
      }
    },
    'http://www.hunde-lodge.de': {
      de: {
        links: hundeLodgeDeDeLinks,
        trustpilot: null
      }
    },
    'http://www.kiel-travel.de': {
      de: {
        links: kielTravelDeDeLinks,
        trustpilot: kielTravelDeDeTrustpilot
      }
    },
    'http://www.luebeck-travel.de': {
      de: {
        links: luebeckTravelDeDeLinks,
        trustpilot: luebeckTravelDeDeTrustpilot
      }
    },
    'http://www.lueneburg-travel.de': {
      de: {
        links: lueneburgTravelDeDeLinks,
        trustpilot: lueneburgTravelDeDeTrustpilot
      }
    },
    'http://www.masuren-travel.com': {
      de: {
        links: masurenTravelComDeLinks,
        trustpilot: null
      },
      en: {
        links: masurenTravelComEnLinks,
        trustpilot: null
      }
    },
    'http://www.mueritz-travel.de': {
      de: {
        links: mueritzTravelDeDeLinks,
        trustpilot: null
      }
    },
    'http://www.polen-travel.com': {
      de: {
        links: polenTravelComDeLinks,
        trustpilot: null
      }
    },
    'http://www.praha-lodge.com': {
      de: {
        links: prahaLodgeComDeLinks,
        trustpilot: null
      }
    },
    'http://www.ruegen-travel.de': {
      de: {
        links: ruegenTravelDeDeLinks,
        trustpilot: ruegenTravelDeDeTrustpilot
      }
    },
    'http://www.scharbeutz-travel.de': {
      de: {
        links: scharbeutzTravelDeDeLinks,
        trustpilot: null
      }
    },
    'http://www.schwarzwald-travel.de': {
      de: {
        links: schwarzwaldTravelDeDeLinks,
        trustpilot: schwarzwaldTravelDeDeTrustpilot
      }
    },
    'http://www.see-ferienwohnung.de': {
      de: {
        links: seeFerienwohnungDeDeLinks,
        trustpilot: null
      }
    },
    'http://www.sizilien-travel.com': {
      de: {
        links: sizilienTravelComDeLinks,
        trustpilot: null
      },
      en: {
        links: sizilienTravelComEnLinks,
        trustpilot: null
      }
    },
    'http://www.stpeterording-travel.de': {
      de: {
        links: stpeterordingTravelDeDeLinks,
        trustpilot: stpeterordingTravelDeDeTrustpilot
      }
    },
    'http://www.sylt-travel.de': {
      de: {
        links: syltTravelDeDeLinks,
        trustpilot: syltTravelDeDeTrustpilot
      },
      en: {
        links: syltTravelDeEnLinks,
        trustpilot: null
      }
    },
    'http://www.timmendorferstrand-travel.de': {
      de: {
        links: timmendorferstrandTravelDeDeLinks,
        trustpilot: timmendorferstrandTravelDeDeTrustpilot
      }
    },
    'http://www.travanto.de': {
      de: {
        links: travantoDeDeLinks,
        trustpilot: travantoDeDeTrustpilot
      },
      en: {
        links: travantoDeEnLinks,
        trustpilot: null
      }
    },
    'http://www.usedom-travel.net': {
      de: {
        links: usedomTravelNetDeLinks,
        trustpilot: null
      }
    },
    'http://www.wintersport-lodge.de': {
      de: {
        links: wintersportLodgeDeDeLinks,
        trustpilot: null
      }
    }
  }

  if (
    !Object.prototype.hasOwnProperty.call(dataForAllPortals, currentPortalString) ||
    !Object.prototype.hasOwnProperty.call(dataForAllPortals[currentPortalString], currentLangString)
  ) {
    // eslint-disable-next-line no-console
    console.error(`Error: undefined portal or no data available for the portal+language: ${currentPortalString}, ${currentLangString}`)
    return {
      links: errorData.links,
      trustpilot: errorData.trustpilot
    }
  }

  function mergeWithDefaultDataFile (portalDataFile, defaultDataFile) {
    if (portalDataFile === null) {
      return
    }
    for (const key in defaultDataFile) {
      if (!Object.prototype.hasOwnProperty.call(defaultDataFile, key) || defaultDataFile[key] === undefined) {
        continue
      }
      if (!Object.prototype.hasOwnProperty.call(portalDataFile, key) || portalDataFile[key] === undefined) {
        portalDataFile[key] = defaultDataFile[key]
      }
    }
  }

  // portalData.value = dataForAllPortals[currentPortalString][currentLangString]

  const defaultLinks = defaultData[currentLangString].links
  const portalLinks = dataForAllPortals[currentPortalString][currentLangString].links
  const defaultTrustpilot = defaultData[currentLangString].trustpilot
  const portalTrustpilot = dataForAllPortals[currentPortalString][currentLangString].trustpilot

  mergeWithDefaultDataFile(portalLinks, defaultLinks)
  mergeWithDefaultDataFile(portalTrustpilot, defaultTrustpilot)

  return {
    links: portalLinks,
    trustpilot: portalTrustpilot,
    portalsWithLongLogos: ['timmendorferstrand-travel.de', 'stpeterording-travel.de']
  }
}

export default usePortalData
